import { motion } from "framer-motion";
import styled from "styled-components";
import {
  CarouselContainer,
  FeaturedTopics,
  Icons,
} from "./IntroCarousel.styles";
import { Typography } from "@mui/material";
import { Box } from "lucide-react";
import colors from "./colors";
import { themeColors } from "../utils/consts";


export const Image = styled.img`
  width: 240px;
  height: auto;
  margin: 10px;

  @media (max-width: 480px) {
    width: 180px;
    height: auto;
    margin-top: 20px;
  }
`;

export const SpeechBubble = styled.div`
  position: relative;
  background-color: #eef8ff;
  padding: 20px 30px;
  border-radius: 24px;
  width: 300px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 20px 20px 0;
    border-style: solid;
    border-color: #eef8ff transparent transparent transparent;
  }
`;

export const StartButton = styled(motion.button)`
  background-color: #2965ff;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 30px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #1a47b8;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    padding: 8px 16px;
    font-size: 15px;
    margin-top: 40px;
  }
`;

export const AllSetWrapper = styled.div<{ theme: string }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => {
    const theme = props.theme || "1";
    return (
      themeColors[theme as keyof typeof themeColors]?.background ||
      themeColors["1"].background
    );
  }};

  padding-block: 60px;
  gap: 10px
  }
`;

export const ChatInput = styled.div`
  display: flex;
  gap: 10px;
  margin-top: auto;
  padding: 10px;

  input {
    flex: 1;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 16px;
  }
`;

export const ChatPageIcons = styled(Icons)`
  & {
    filter: invert(11%) sepia(2%) hue-rotate(182deg) brightness(92%)
      contrast(67%);
    &:hover {
      filter: invert(46%) sepia(9%) saturate(341%) hue-rotate(182deg)
        brightness(94%) contrast(87%);
    }
  }
`;
export const CenteredBox = styled(Box)`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  color: ${colors.text};
  font-size: 24px;
  font-weight: 600;
  position: "absolute",
  bottom: "30%",
`;

export const AvatarCircleContainer = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: ${(props) => {
    switch (props.datatype) {
      case "1":
        return "#FFF3E0";
      case "2":
        return "#E3F2FD";
      case "3":
        return "#F3E5F5";
      default:
        return "#FFF3E0";
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const BaseText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #085cbe;
  text-align: center;
  width: 200px;
`;

export const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const OverlayText = styled(BaseText)`
  margin-left: auto;
`;

export const BubbleText = styled(BaseText)`
  margin: 0;
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  backdrop-filter: blur(4px);
`;

export const Spinner = styled.div`
  width: 4rem;
  height: 4rem;
  border: 4px solid #3b82f6;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const ThemedFeaturedTopics = styled(FeaturedTopics)<{ theme: string }>`
  color: ${(props) => {
    const theme = props.theme || "1";
    return (
      themeColors[theme as keyof typeof themeColors]?.text ||
      themeColors["1"].text
    );
  }};
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  font-size: 20px;
  margin-top: 20px;

  @media (min-width: 1024px) {
    margin-bottom: 24px;
    font-size: 22px;
  }
  @media (max-width: 480px) {
    margin-top: 20px;
  }
`;

export const AudioContainer = styled.div`
  margin: 10px 0;
  padding-left: 50px;
  border-radius: 11px;

  @media (min-width: 1024px) and (max-width: 1366px) {
    padding-left: 80px;
    margin: 15px 0;

    audio {
      max-width: 400px;
      height: 48px;
    }
  }

  audio {
    width: 100%;
    max-width: 300px;
    height: 40px;

    &::-webkit-media-controls-panel {
      background-color: #ffecd5;
    }
  }
`;

export const Message = styled.div<{ theme: string; isRtl: boolean }>`
  padding: 8px 12px;
  margin: 4px;
  max-width: 85%;
  border-radius: 8px;
  background-color: ${(props) => {
    const theme = props.theme || "1";
    return (
      themeColors[theme as keyof typeof themeColors]?.secondary ||
      themeColors["1"].secondary
    );
  }};
  color: #000000;
  align-self: flex-start;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  direction: ${(props) => (props.isRtl ? "rtl" : "ltr")};

  @media (min-width: 768px) {
    padding: 10px 15px;
    margin: 5px;
    font-size: 16px;
    max-width: 75%;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    padding: 15px 20px;
    line-height: 1.5;
  }
`;

export const ChatAvatar = styled(motion.img)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
  margin-bottom: 4px;

  @media (min-width: 768px) {
    width: 60px;
    height: 60px;
    margin-right: 12px;
    margin-bottom: 8px;
  }

  @media (min-width: 1024px) {
    width: 90px;
    height: 90px;
    margin-right: 20px;
    margin-bottom: 12px;
  }
`;

export const IconButton = styled.button<{ theme: string; showChat: boolean }>`
  background: ${(props) =>
    props.showChat ? "rgba(255, 255, 255, 0.2)" : "none"};
  border: none;
  cursor: pointer;
  padding: 8px;
  color: ${(props) => {
    const theme = props.theme || "1";
    return (
      themeColors[theme as keyof typeof themeColors]?.text ||
      themeColors["1"].text
    );
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 50%;
  backdrop-filter: ${(props) => (props.showChat ? "blur(4px)" : "none")};
  box-shadow: ${(props) =>
    props.showChat ? "0 2px 8px rgba(0, 0, 0, 0.1)" : "none"};

  svg {
    width: 20px;
    height: 20px;

    @media (min-width: 768px) {
      width: 24px;
      height: 24px;
    }

    @media (min-width: 1024px) {
      width: 38px;
      height: 38px;
    }
  }

  &:hover {
    background-color: ${(props) => {
      if (props.showChat) return "rgba(255, 255, 255, 0.3)";
      const theme = props.theme || "1";
      return (
        `${themeColors[theme as keyof typeof themeColors]?.text}15` ||
        `${themeColors["1"].text}15`
      );
    }};
    transform: rotate(30deg);
  }
`;

export const LoadingOverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  backdrop-filter: blur(4px);
  z-index: 20;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const ChatButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  width: 100%;

  .button-pair {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    width: 100%;
  }

  @media (min-width: 1025px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 32px 24px;
    gap: 24px;
    max-width: 90%;
    margin: 0 auto;

    .button-pair {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
    @media (max-width: 480px) {
     .button-pair {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
`;

export const ChatButton = styled.button`
  background-color: #eef8ff;
  color: #085cbe;
  padding: 12px 16px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  white-space: normal;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.3;

  @media (max-width: 1024px) {
    padding: 12px 16px;
    font-size: 14px;
  }

  @media (min-width: 1025px) {
    padding: 16px 32px;
    font-size: 18px;
    border-radius: 12px;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

export const ChatContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent;
  flex: 1;
  margin: 0 auto;
  height: calc(100vh - 100px);
  margin-top: calc(60px + 16px);

  @media (min-width: 768px) {
    height: calc(100vh - 102px);
    padding: 32px;
    margin-block: calc(70px + 16px);
    width: calc(100% - 70px);
    margin-inline: auto;
  }

  @media (max-width: 767px) {
    height: calc(100vh - 72px);
    margin-top: calc(40px + 16px);
    padding: 16px;
    border-radius: 8px;
  }

  @media (max-width: 480px) {
    height: calc(100vh - 72px);
    margin-top: calc(40px);
    padding: 16px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #085cbe;
    border-radius: 4px;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: calc(60px + 2rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    margin-top: calc(70px + 2rem);
  }

  @media (max-width: 480px) {
    margin-top: calc(40px + 2rem);
  }
`;

export const NavigationButtons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  direction: ltr;
`;

export const NavButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  min-width: 120px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const CircleButton = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #085cbe;
  border: none;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 30px;
  margin-bottom: 10px;

  &:hover {
    transform: scale(1.05);
    background-color: #1d4ed8;
  }

  &:disabled {
    background-color: #94a3b8;
    cursor: not-allowed;
    transform: none;
  }

  svg {
    color: white;
    width: 24px;
    height: 24px;
    direction: rtl;
  }
`;

export const Header = styled.div<{ theme: string; showChat: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 768px) {
    padding: 48px 68px;
  }

  @media (max-width: 480px) {
    padding: 8px 18px;
  }
`;

export const BubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 60px;
  width: 100%;
  z-index: 1;

  @media (min-width: 768px) {
    padding-top: 70px;
  }

  @media (max-width: 480px) {
    padding-top: 40px;
  }
`;

export const ThemedContainer = styled.div<{
  theme: string;
  showChat: boolean;
  currentBackground: number;
}>`
  background-image: ${(props) =>
    props.showChat
      ? `url(${require(`../assets/backgrounds/bg${props.currentBackground}.png`)})`
      : "none"};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  background-color: ${(props) => {
    const theme = props.theme || "1";
    return (
      themeColors[theme as keyof typeof themeColors]?.background ||
      themeColors["1"].background
    );
  }};
  color: ${(props) => {
    const theme = props.theme || "1";
    return (
      themeColors[theme as keyof typeof themeColors]?.text ||
      themeColors["1"].text
    );
  }};
  width: 100vw;
  margin: 0;
  padding: 0;
  flex-direction: column;
  overflow-x: hidden;

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

export const StyledButton = styled.button`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 9999px;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;
