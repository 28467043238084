import styled from "styled-components";
import { RTLProps } from "../utils/interfaces";

export const SettingsContainer = styled.div`
  max-width: 900px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 30px 20px 80px;
  overflow: auto;

  @media (max-width: 768px) {
    padding: 20px 16px 60px;
  }
`;

export const LinkSection = styled.div`
  background-color: #f0f8ff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

export const Text = styled.p`
  color: #085cbe;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
  padding: 10px 40px;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 10px 20px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    padding: 10px;
  }
`;

export const Coming = styled.p`
  color: #ff8a00;
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
  margin-left: 10px;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const LinkBox = styled.div`
  background-color: white;
  border: 1px solid #4a90e2;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const Link = styled.span`
  color: #085cbe;
  word-break: break-all;
  width: 80%;
  margin-left: 10px;
  font-size: 20px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    text-align: center;
    font-size: 16px;
  }
`;

export const CodeSection = styled.div`
  text-align: center;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
`;

export const CodeTitle = styled.h2`
  color: #085cbe;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;

  @media (max-width: 480px) {
    font-size: 15px;
    margin-top: 5px;
  }
`;

export const Code = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #085cbe;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

export const CustomTopicInput = styled.input`
  width: 45%;
  padding: 5px;
  border-radius: 10px;
  border: none;
  background-color: #f4f4f4;
  cursor: not-allowed;
  height: 30px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const DialogContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 1000px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const DialogTitle = styled.h2`
  color: #085cbe;
  font-size: 24px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const DialogCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
`;

export const DialogSection = styled.div`
  margin-bottom: 20px;
`;

export const DialogSectionTitle = styled.h3`
  color: #085cbe;
  font-size: 18px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const DialogInput = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #085cbe;
  border-radius: 5px;
  font-size: 16px;

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export const DialogButton = styled.button`
  background-color: #085cbe;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 220px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DialogInputGroup = styled.div`
  margin-bottom: 10px;

  label {
    display: block;
    margin-bottom: 5px;
    color: #085cbe;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

export const ComingSoon = styled.span`
  color: #ffa500;
  font-size: 14px;

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const SignOut = styled.button`
  background: none;
  border: none;
  color: #f37c7c;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const ActionButton = styled.button`
  background: none;
  border: none;
  color: #085cbe;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  gap: 8px;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  img {
    width: 48px;
    height: 48px;

    @media (max-width: 768px) {
      width: 40px;
      height: 40px;
    }

    @media (max-width: 480px) {
      width: 32px;
      height: 32px;
    }
  }

  @media (max-width: 768px) {
    width: 140px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    width: 120px;
    font-size: 12px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 10px;

  @media (max-width: 768px) {
    gap: 15px;
  }

  @media (max-width: 480px) {
    gap: 10px;
  }
`;

export const CopyButton = styled.button`
  background: none;
  border: none;
  color: #4a90e2;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  img {
    width: 32px;
    height: 32px;

    @media (max-width: 768px) {
      width: 28px;
      height: 28px;
    }

    @media (max-width: 480px) {
      width: 24px;
      height: 24px;
      display: none;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 20px;
  position: relative;
  width: 100%;

  @media (max-width: 768px) {
    margin-block: 15px;
  }

  @media (max-width: 480px) {
    margin-block: 10px;
  }
`;

export const RightContainer = styled.div`
  position: absolute;
  top: 10%;
  right: 10%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  gap: 20px;
  z-index: 10;

  @media (max-width: 768px) {
    gap: 15px;
  }

  @media (max-width: 480px) {
    gap: 10px;
  }
`;

export const Title = styled.h1`
  color: #085cbe;
  font-weight: bold;
  font-size: 32px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 22px;
  }
`;

export const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const MenuIcon = styled.img`
  width: 32px;
  height: 32px;

  @media (max-width: 768px) {
    width: 28px;
    height: 28px;
  }

  @media (max-width: 480px) {
    width: 24px;
    height: 24px;
  }
`;

export const SettingsSection = styled.div<RTLProps>`
  background-color: #f0f8ff;
  border-radius: 10px;
  padding: 20px;
  direction: ${(props) => (props.$isRTL ? "rtl" : "ltr")};

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const SettingGroup = styled.div<RTLProps>`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.$isRTL ? "flex-end" : "flex-start")};
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    align-items: ${(props) => (props.$isRTL ? "flex-end" : "flex-start")};
  }
`;

export const Settings = styled.div<RTLProps>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #085cbe;
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  margin-top: 30px;
  direction: ${(props) => (props.$isRTL ? "rtl" : "ltr")};

  @media (max-width: 768px) {
    padding: 15px;
    gap: 15px;
  }
`;

export const SettingLabel = styled.label<RTLProps>`
  text-align: ${(props) => (props.$isRTL ? "right" : "left")};
  direction: ${(props) => (props.$isRTL ? "rtl" : "ltr")};
  margin: ${(props) => (props.$isRTL ? "0 0 0 20px" : "0 20px 0 0")};
  color: #085cbe;
  font-size: 20px;
  font-weight: 700;
  min-width: 130px;

  &.custom-topic {
    color: #e6e4ed;
  }

  @media (max-width: 768px) {
    margin: 0 0 5px 0;
    font-size: 18px;
    width: 80px;
  }
`;
export const SettingOptions = styled.div<RTLProps>`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  direction: ${(props) => (props.$isRTL ? "rtl" : "ltr")};
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  float: ${(props) => (props.$isRTL ? "right" : "left")};
`;

export const SettingOption = styled.button<{
  $isselected?: boolean;
  $isRTL?: boolean;
}>`
  color: ${(props) => (props.$isselected ? "#FF8A00" : "#085cbe")};
  border: ${(props) =>
    props.$isselected ? "2px solid #FF8A00" : "1px solid #085cbe"};
  background: ${(props) =>
    props.$isselected ? "rgba(255, 138, 0, 0.2)" : "white"};
  padding: 5px 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  border-radius: 5px;
  direction: ${(props) => (props.$isRTL ? "rtl" : "ltr")};
  text-align: ${(props) => (props.$isRTL ? "right" : "left")};
  float: ${(props) => (props.$isRTL ? "right" : "left")};

  @media (max-width: 768px) {
    font-size: 16px;
    width: fit-content;
  }
`;

export const StyledSelect = styled.select<RTLProps>`
  padding: 8px 12px;
  border-radius: 8px;
  border: 2px solid #085cbe;
  background-color: white;
  color: #085cbe;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  direction: ${(props) => (props.$isRTL ? "rtl" : "ltr")};
  padding-${(props) => (props.$isRTL ? "left" : "right")}: 24px; /* Space for the arrow */
  
  /* Reset default appearance */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23085cbe' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: ${(props) => (props.$isRTL ? "8px" : "calc(100% - 8px)")} center;
  background-size: 16px;

  & option {
    direction: ${(props) => (props.$isRTL ? "rtl" : "ltr")};
    text-align: ${(props) => (props.$isRTL ? "right" : "left")};
  }

  &:focus {
    border-color: #4f46e5;
    outline: none;
  }

  &:hover {
    border-color: #4f46e5;
  }
`;
export const DropdownContainer = styled.div`
  width: 120px;
  margin-bottom: 8px;
`;