import { FC, useContext, useEffect, useRef, useState } from "react";
import {
  ContentWrapper,
  TopicButton,
  FeaturedTopicsContainer,
  SpeechBubble,
  BubbleContainer,
  ImageAvatar,
  EmojiSpan,
  TextSpan,
} from "../styles/IntroCarousel.styles";
import centeredCat from "../assets/centeredCat.png";
import {
  AudioContainer,
  ChatAvatar,
  ChatButton,
  ChatButtonsContainer,
  ChatContainer,
  Message,
  MessageContainer,
  StartButton,
  ThemedContainer,
  ThemedFeaturedTopics,
  LoadingOverlayContainer,
  LoadingWrapper,
  Header,
  IconButton,
  CircleButton,
} from "../styles/chatPageStyle";
import { selectTopic, sendChatMessage } from "../apis/chatApi";
import { useTranslation } from "react-i18next";
import type { ChatSessionResponse, TopicData } from "../utils/interfaces";
import CustomAudioPlayer from "../components/AudioPlayer";
import birdAvatar from "../assets/birdAvatar.png";
import catAvatar from "../assets/catAvatar.png";
import robotAvatar from "../assets/robotAvatar.png";
import { ArrowLeft, Home, Settings } from "lucide-react";
import { LanguageContext } from "../hooks/languageContext";
import LoadingAnimation from "../components/LoadingAnimation";
import birdAnimation from "../assets/birdAnimation.svg";
import catAnimation from "../assets/catAnimation.svg";
import robotAnimation from "../assets/robotAnimation.svg";
import IntroAudioPlayer, {
  IntroAudioPlayerRef,
} from "../components/IntroAudioPlayer";

interface Message {
  content: string;
  followUps: string[];
  sessionId?: string;
  avatarName: string;
  audioData?: string;
}

interface ChatPageProps {
  initialData: ChatSessionResponse;
  onNavigateToSetup: () => void;
}

const STORAGE_KEYS = {
  CHAT_STATE: "chatState",
  MESSAGES: "chatMessages",
  SESSION_ID: "chatSessionId",
  SELECTED_TOPIC: "selectedTopic",
  CURRENT_BACKGROUND: "currentBackground",
  LISTENED_MESSAGES: "listenedMessages",
};

const ChatPage: FC<ChatPageProps> = ({ initialData, onNavigateToSetup }) => {
  const transformedSubtopics: TopicData[] = initialData.subtopics.map(
    (topic) => ({
      ...topic,
      topic: topic.topic || "Art",
    })
  );

  const lastMessageRef = useRef<HTMLDivElement>(null);
  const [selectedTopic, setSelectedTopic] = useState<any | null>(() => {
    const saved = localStorage.getItem(STORAGE_KEYS.SELECTED_TOPIC);
    return saved ? JSON.parse(saved) : null;
  });

  const [sessionId, setSessionId] = useState<string>(() => {
    return (
      localStorage.getItem(STORAGE_KEYS.SESSION_ID) || initialData.sessionId
    );
  });

  const [listenedMessages, setListenedMessages] = useState<number[]>(() => {
    const saved = localStorage.getItem(STORAGE_KEYS.LISTENED_MESSAGES);
    return saved ? JSON.parse(saved) : [];
  });

  const [playingMessageIndex, setPlayingMessageIndex] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const currentTheme = sessionStorage.getItem("colorTheme") || "1";
  const { language } = useContext(LanguageContext);
  const isRTL = language === "Hebrew" || language === "he";
  const selcedtedAvatarType = sessionStorage.getItem("avatarType") || "1";
  const introAudioRef = useRef<IntroAudioPlayerRef>(null);

  const [showChat, setShowChat] = useState(() => {
    return localStorage.getItem(STORAGE_KEYS.CHAT_STATE) === "true";
  });

  const [messages, setMessages] = useState<Message[]>(() => {
    const saved = localStorage.getItem(STORAGE_KEYS.MESSAGES);
    return saved ? JSON.parse(saved) : [];
  });

  const [currentBackground, setCurrentBackground] = useState<number>(() => {
    return parseInt(
      localStorage.getItem(STORAGE_KEYS.CURRENT_BACKGROUND) || "1"
    );
  });

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.CHAT_STATE, showChat.toString());
    localStorage.setItem(STORAGE_KEYS.MESSAGES, JSON.stringify(messages));
    localStorage.setItem(STORAGE_KEYS.SESSION_ID, sessionId);
    localStorage.setItem(
      STORAGE_KEYS.SELECTED_TOPIC,
      JSON.stringify(selectedTopic)
    );
    localStorage.setItem(
      STORAGE_KEYS.CURRENT_BACKGROUND,
      currentBackground.toString()
    );
    localStorage.setItem(
      STORAGE_KEYS.LISTENED_MESSAGES,
      JSON.stringify(listenedMessages)
    );
  }, [
    showChat,
    messages,
    sessionId,
    selectedTopic,
    currentBackground,
    listenedMessages,
  ]);

  const handleNavigateToSetup = () => {
    onNavigateToSetup();
  };

  const handleEndSession = () => {
    localStorage.removeItem(STORAGE_KEYS.CHAT_STATE);
    localStorage.removeItem(STORAGE_KEYS.MESSAGES);
    localStorage.removeItem(STORAGE_KEYS.SESSION_ID);
    localStorage.removeItem(STORAGE_KEYS.SELECTED_TOPIC);
    localStorage.removeItem(STORAGE_KEYS.CURRENT_BACKGROUND);
    localStorage.removeItem(STORAGE_KEYS.LISTENED_MESSAGES);

    setShowChat(false);
    setMessages([]);
    setSelectedTopic(null);
    setPlayingMessageIndex(-1);
    setListenedMessages([]);
  };

  const getRandomBackground = () => {
    const min = 1;
    const max = 4;
    let newBg = Math.floor(Math.random() * (max - min + 1)) + min;

    while (newBg === currentBackground) {
      newBg = Math.floor(Math.random() * (max - min + 1)) + min;
    }
    return newBg;
  };
  const decodeBase64ToAudioData = (base64Audio: string): string => {
    const binaryString = atob(base64Audio);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const audioBlob = new Blob([bytes.buffer], { type: "audio/mpeg" });
    return URL.createObjectURL(audioBlob);
  };

  const handleAudioPlay = (index: number) => {
    setPlayingMessageIndex(index);
  };

  const handleAudioPause = () => {
    setPlayingMessageIndex(-1);
  };

  const handleAudioEnded = (index: number) => {
    setPlayingMessageIndex(-1);
    if (!listenedMessages.includes(index)) {
      setListenedMessages((prev) => [...prev, index]);
    }
  };

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [messages]);

  const handleTopicClick = (topic: any) => {
    setSelectedTopic(topic);
    introAudioRef.current?.stopAudio();
  };

  const handleStartClick = async () => {
    try {
      const response = await selectTopic(selectedTopic.id);
      setCurrentBackground(getRandomBackground());
      setShowChat(true);
      setLoading(true);
      if (response.ok) {
        await handleStreamResponse(response);
      }
    } catch (error) {
      console.error("Error in handleStartClick:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFollowUpClick = async (topic: string) => {
    if (!sessionId) return;
    setLoading(true);
    try {
      const response = await sendChatMessage(sessionId, topic);
      await handleStreamResponse(response);
    } catch (error) {
      console.error("Error in handleFollowUpClick:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleStreamResponse = async (response: Response) => {
    try {
      const responseData = await response.json();
      const {
        content,
        followUps,
        sessionId: newSessionId,
        avatarName,
        audio: base64Audio,
      } = responseData;

      const audioData = base64Audio
        ? decodeBase64ToAudioData(base64Audio)
        : undefined;

      const currentMessage: Message = {
        content: content.replace(/\n/g, "\\n"),
        followUps,
        sessionId: newSessionId,
        avatarName,
        audioData,
      };

      if (newSessionId) {
        setSessionId(newSessionId);
      }

      setMessages((prevMessages) => [...prevMessages, currentMessage]);
    } catch (error) {
      console.error("Error processing response:", error);
    }
  };

  const getTopicStyle = (index: number) => {
    const colors = [
      { backgroundColor: "#e3f2fd", color: "#1565c0" },
      { backgroundColor: "#f3e5f5", color: "#6a1b9a" },
      { backgroundColor: "#e8f5e9", color: "#1b5e20" },
      { backgroundColor: "#fff3e0", color: "#e65100" },
      { backgroundColor: "#fce4ec", color: "#880e4f" },
      { backgroundColor: "#e8eaf6", color: "#1a237e" },
    ];

    return colors[index] || colors[0];
  };

  const splitEmojiAndText = (text: string) => {
    const firstLine = text.split("\n")[0];

    const endEmojiMatch = firstLine.match(
      /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27BF]$/
    );
    if (endEmojiMatch) {
      const emoji = endEmojiMatch[0];
      const textContent = firstLine.slice(0, -emoji.length).trim();
      return { emoji, text: textContent };
    }

    const emojiMatch = firstLine.match(
      /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27BF]/
    );
    if (emojiMatch) {
      const emoji = emojiMatch[0];
      if (emojiMatch.index === 0) {
        return { emoji, text: firstLine.slice(emoji.length).trim() };
      }
      const textContent = firstLine.slice(0, emojiMatch.index).trim();
      return { emoji, text: textContent };
    }

    return { emoji: "", text: firstLine };
  };

  const renderTopicSelection = () => (
    <ContentWrapper>
      {selectedTopic ? (
        <>
          <ThemedFeaturedTopics theme={currentTheme || "1"}>
            {t("topic_learn_today")}
          </ThemedFeaturedTopics>
          <TopicButton style={getTopicStyle(1)}>
            {(() => {
              const { emoji, text } = splitEmojiAndText(selectedTopic.text);
              return (
                <>
                  <EmojiSpan>{emoji}</EmojiSpan>
                  <TextSpan>{text}</TextSpan>
                </>
              );
            })()}
          </TopicButton>
          <StartButton
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleStartClick}
          >
            {t("click_to_start")}
          </StartButton>
          <CircleButton>
            <ArrowLeft size={16} onClick={() => setSelectedTopic(null)} />
          </CircleButton>
        </>
      ) : (
        <>
          <ThemedFeaturedTopics theme={currentTheme || "1"}>
            {t("featured_topics")}
          </ThemedFeaturedTopics>
          <FeaturedTopicsContainer>
            {transformedSubtopics.map((topic, index) => {
              const { emoji, text } = splitEmojiAndText(topic.text);
              return (
                <TopicButton
                  key={topic.id}
                  onClick={() => handleTopicClick(topic)}
                  style={getTopicStyle(index)}
                >
                  <EmojiSpan>{emoji}</EmojiSpan>
                  <TextSpan>{text}</TextSpan>
                </TopicButton>
              );
            })}
          </FeaturedTopicsContainer>
        </>
      )}
    </ContentWrapper>
  );

  const avatarAnimation = {
    animate: {
      scale: [1, 1.1, 1],
      transition: {
        duration: 1,
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
  };

  const LoadingOverlayComponent = () => (
    <LoadingOverlayContainer>
      <LoadingWrapper>
        <LoadingAnimation
          avatarType={sessionStorage.getItem("avatarType") || "1"}
          getAvatarSrc={getAvatarSVG}
        />
      </LoadingWrapper>
    </LoadingOverlayContainer>
  );

  const getAvatarSVG = (avatarType: string) => {
    switch (avatarType) {
      case "1":
        return catAnimation;
      case "2":
        return birdAnimation;
      case "3":
        return robotAnimation;
      default:
        return robotAnimation;
    }
  };

  const getAvatarSrc = (avatarType: string) => {
    switch (avatarType) {
      case "1":
        return centeredCat;
      case "2":
        return birdAvatar;
      case "3":
        return robotAvatar;
      default:
        return robotAvatar;
    }
  };

  const getAvatarIcon = (avatarType: string) => {
    switch (avatarType) {
      case "1":
        return catAvatar;
      case "2":
        return birdAvatar;
      case "3":
        return robotAvatar;
      default:
        return robotAvatar;
    }
  };

  const renderChat = () => (
    <ChatContainer className="chat-messages">
      {messages.map((message, index) => (
        <div
          key={index}
          className="message-container p-2"
          ref={index === messages.length - 1 ? lastMessageRef : null}
        >
          <MessageContainer>
            <ChatAvatar
              src={getAvatarIcon(selcedtedAvatarType)}
              alt="Tuti"
              initial={{ scale: 1 }}
              animate={
                playingMessageIndex === index ? avatarAnimation.animate : {}
              }
            />
            <Message theme={currentTheme} isRtl={isRTL} className="bot">
              {message.content}
            </Message>
          </MessageContainer>

          <AudioContainer>
            {message.audioData && (
              <CustomAudioPlayer
                src={message.audioData}
                index={index}
                {...(playingMessageIndex === index && { isPlaying: true })}
                onPlay={handleAudioPlay}
                onPause={handleAudioPause}
                onEnded={handleAudioPause}
                theme={sessionStorage.getItem("colorTheme") || "1"}
                isListened={listenedMessages.includes(index)}
              />
            )}
          </AudioContainer>
          <ChatButtonsContainer>
            {chunk(message.followUps, 2).map((buttonPair, pairIndex) => (
              <div key={pairIndex} className="button-pair">
                {buttonPair.map((followUp, buttonIndex) => (
                  <ChatButton
                    key={buttonIndex}
                    onClick={() => handleFollowUpClick(followUp)}
                  >
                    {followUp}
                  </ChatButton>
                ))}
              </div>
            ))}
          </ChatButtonsContainer>
        </div>
      ))}
    </ChatContainer>
  );

  const chunk = (array: string[], size: number) => {
    return Array.from({ length: Math.ceil(array.length / size) }, (v, i) =>
      array.slice(i * size, i * size + size)
    );
  };
  return (
    <ThemedContainer
      theme={currentTheme}
      showChat={showChat}
      currentBackground={currentBackground}
    >
      {!showChat && <IntroAudioPlayer ref={introAudioRef} />}
      {loading && <LoadingOverlayComponent />}

      <Header theme={currentTheme} showChat={showChat}>
        <IconButton
          theme={currentTheme}
          showChat={showChat}
          onClick={handleNavigateToSetup}
          aria-label="Settings"
        >
          <Settings />
        </IconButton>
        {showChat && (
          <IconButton
            theme={currentTheme}
            showChat={showChat}
            onClick={handleEndSession}
            aria-label="Home"
          >
            <Home />
          </IconButton>
        )}
      </Header>

      {!showChat && (
        <BubbleContainer>
          <SpeechBubble>{t("bubble_choose_topic")}</SpeechBubble>
          <ImageAvatar src={getAvatarSrc(selcedtedAvatarType)} alt="avatar" />
        </BubbleContainer>
      )}

      {!showChat ? renderTopicSelection() : renderChat()}
    </ThemedContainer>
  );
};

export default ChatPage;
