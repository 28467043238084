import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../hooks/languageContext";
import {
  BotSettings,
  getBotById,
  getBotSettings,
  Language,
  updateTutor,
} from "../apis/settingApi";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import {
  SettingsContainer,
  Header,
  Title,
  LinkSection,
  Text,
  LinkBox,
  Link,
  CopyButton,
  CodeSection,
  CodeTitle,
  Code,
  ButtonGroup,
  ActionButton,
  SettingsSection,
  SettingGroup,
  SettingLabel,
  SettingOptions,
  SettingOption,
  CustomTopicInput,
  MenuButton,
  MenuIcon,
  RightContainer,
  Settings,
  Coming,
  DialogButton,
  DropdownContainer,
  StyledSelect,
} from "../styles/SettingsStyles";
import { BirdImage, FoxImage } from "../styles/WelcomePageStyles";
import birdIcon from "../assets/bird.png";
import catIcon from "../assets/cat.png";
import copyCode from "../assets/copyCode.svg";
import openIcon from "../assets/open.svg";
import shareIcon from "../assets/share.svg";
import copyIcon from "../assets/copy.svg";
import menuIcon from "../assets/menu.svg";
import SettingsDialog from "../components/SettingsDialog";
import { Check } from "lucide-react";

interface SettingsPageProps {
  onLogout: () => void;
  idToken: string;
  uid: string;
}

const defaultSettings: BotSettings = {
  languages: [{ alpha2: "en", English: "English" }],
  topics: ["General"],
  answerLength: ["Short"],
};

const SettingsPage: React.FC<SettingsPageProps> = ({
  onLogout,
  idToken,
  uid,
}) => {
  const [currentToken, setCurrentToken] = useState<string>(idToken);
  const { language } = useContext(LanguageContext);
  const { t, i18n } = useTranslation();
  const [chatbotLink, setChatbotLink] = useState("");
  const [code, setCode] = useState("1234");
  const [selectedAge, setSelectedAge] = useState("5");
  const ageOptions = Array.from({ length: 8 }, (_, i) => (i + 5).toString());
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    defaultSettings.languages[0]
  );
  const [selectedTopics, setSelectedTopics] = useState<string[]>([
    defaultSettings.topics[0],
  ]);
  const [selectedAnswer, setSelectedAnswer] = useState(
    defaultSettings.answerLength[0]
  );
  const [customTopic, setCustomTopic] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState<Language[]>(
    defaultSettings.languages
  );
  const [availableTopics, setAvailableTopics] = useState<string[]>(
    defaultSettings.topics
  );
  const [availableAnswerLengths, setAvailableAnswerLengths] = useState<
    string[]
  >(defaultSettings.answerLength);
  const [isLoading, setIsLoading] = useState(true);
  const [chatbotId, setChatbotId] = useState<string>("");
  const isRTL = language === "Hebrew";

  useEffect(() => {
    const storedToken = sessionStorage.getItem("token");
    if (storedToken && !currentToken) {
      setCurrentToken(storedToken);
    } else if (idToken && currentToken !== idToken) {
      setCurrentToken(idToken);
      sessionStorage.setItem("token", idToken);
    }
  }, [idToken, currentToken]);

  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        const token = currentToken || sessionStorage.getItem("token");
        const storedChatbotId = sessionStorage.getItem("chatbotId");

        if (!token || !storedChatbotId) {
          console.warn("Missing token or chatbot ID");
          toast.error(t("authError"), {
            position: "bottom-center",
            style: { backgroundColor: "#EF4444", color: "white" },
          });
          return;
        }

        const settings = await getBotById(token, storedChatbotId);

        const languageObj = availableLanguages.find(
          (lang) => lang.alpha2 === settings.language
        );
        if (languageObj) {
          setSelectedLanguage(languageObj);
        }

        if (settings.topic && Array.isArray(settings.topic)) {
          setSelectedTopics(settings.topic);
        }
        if (settings.answerLength) {
          setSelectedAnswer(settings.answerLength);
          setAvailableAnswerLengths(["VERY_SHORT", "SHORT"]);
        }

        if (settings.code) {
          setCode(settings.code);
        }

        if (settings.link) {
          setChatbotLink(settings.link);
          sessionStorage.setItem("link", settings.link);
        }
      } catch (error) {
        console.error("Failed to fetch settings:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, [currentToken, t, availableLanguages]);

  useEffect(() => {
    const storedLink = sessionStorage.getItem("link");
    if (storedLink) {
      setChatbotLink(storedLink);
    } else {
      const baseUrl = window.location.origin;
      setChatbotLink(`${baseUrl}/intro?userId=${uid}&lang=${language}`);
    }
    setCode(sessionStorage.getItem("code") || "1234");
  }, [language, uid]);

  useEffect(() => {
    if (!chatbotLink) return;

    try {
      if (chatbotLink.startsWith("http")) {
        const url = new URL(chatbotLink);
        const userIdFromUrl = url.searchParams.get("userId");
        if (userIdFromUrl) {
          setChatbotId(userIdFromUrl);
        }
      }
    } catch (error) {
      console.error("Invalid URL:", chatbotLink);
    }
  }, [chatbotLink]);

  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        const token = currentToken || sessionStorage.getItem("token");

        if (!token) {
          console.warn("No token available");
          toast.error(t("authError"), {
            position: "bottom-center",
            style: { backgroundColor: "#EF4444", color: "white" },
          });
          return;
        }

        const settings = await getBotSettings(token);

        if (settings.languages && Array.isArray(settings.languages)) {
          setAvailableLanguages(settings.languages);
          if (!selectedLanguage || settings.languages.length > 0) {
            setSelectedLanguage(settings.languages[0]);
          }
        }

        if (settings.topics && Array.isArray(settings.topics)) {
          setAvailableTopics(settings.topics);
          if (selectedTopics.length === 0 && settings.topics.length > 0) {
            setSelectedTopics([settings.topics[0]]);
          }
        }

        if (settings.answerLength && Array.isArray(settings.answerLength)) {
          setAvailableAnswerLengths(settings.answerLength);
          if (!selectedAnswer && settings.answerLength.length > 0) {
            setSelectedAnswer(settings.answerLength[0]);
          }
        }
      } catch (error) {
        console.error("Failed to fetch settings:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, [currentToken, t]);

  const handleUpdateSettings = async () => {
    try {
      setIsUpdating(true);
      const token = currentToken || sessionStorage.getItem("token");
      const storedChatbotId = sessionStorage.getItem("chatbotId");

      if (!storedChatbotId || !token) {
        throw new Error("Missing required data");
      }

      const response = await updateTutor(
        storedChatbotId,
        token,
        selectedLanguage.alpha2,
        selectedTopics,
        selectedAnswer,
        undefined, // colorTheme (optional)
        undefined, // avatarType (optional)
        undefined, // azureVoiceType (optional)
        selectedAge
      );

      if (response.link) {
        setChatbotLink(response.link);
        sessionStorage.setItem("link", response.link);
        toast.success(t("settingsUpdated"), {
          position: "bottom-center",
          style: { backgroundColor: "#22C55E", color: "white" },
        });
      }
    } catch (error) {
      console.error("Failed to update settings:", error);
      toast.error(t("updateFailed"), {
        position: "bottom-center",
        style: { backgroundColor: "#EF4444", color: "white" },
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(chatbotLink);
      toast.success(t("linkCopied"), {
        position: "bottom-center",
        style: { backgroundColor: "#22C55E", color: "white" },
      });
    } catch (error) {
      console.error("Failed to copy:", error);
      toast.error(t("copyFailed"), {
        position: "bottom-center",
        style: { backgroundColor: "#EF4444", color: "white" },
      });
    }
  };

  const handleEditCode = () => {
    navigator.clipboard.writeText(code);
  };

  const handleOpenInNewTab = () => {
    window.open(chatbotLink, "_blank");
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: t("shareTutorTitle"),
          text: t("shareTutorText"),
          url: chatbotLink,
        });
      } catch (error) {
        if (error instanceof Error && error.name !== "AbortError") {
          console.error("Error sharing:", error);
        }
      }
    } else {
      handleCopy();
    }
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleLanguageChange = (newLanguage: Language) => {
    setSelectedLanguage(newLanguage);
  };

  const handleTopicToggle = (topic: string) => {
    setSelectedTopics((prevTopics) => {
      if (prevTopics.includes(topic)) {
        return prevTopics.filter((t) => t !== topic);
      } else {
        return [...prevTopics, topic];
      }
    });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ClipLoader color="#4F46E5" size={50} />
      </div>
    );
  }

  return (
    <SettingsContainer>
      <Header>
        <Title>{t("codeTitle")}</Title>
        <RightContainer>
          <MenuButton onClick={handleOpenDialog}>
            <MenuIcon src={menuIcon} alt={t("actions.menu")} />
          </MenuButton>
        </RightContainer>
      </Header>
      <LinkSection>
        <LinkBox>
          <Link
            as="a"
            href={chatbotLink}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline cursor-pointer"
          >
            {chatbotLink}
          </Link>
          <CopyButton onClick={handleCopy}>
            <img src={copyIcon} alt={t("actions.copyCode")} />
          </CopyButton>
        </LinkBox>
        <Text>{t("tutorCode")}</Text>
        <Code>{code}</Code>
        <ButtonGroup>
          <ActionButton onClick={handleEditCode}>
            <img src={copyCode} alt={t("actions.copyCode")} />
            {t("actions.copyCode")}
          </ActionButton>
          <ActionButton onClick={handleOpenInNewTab}>
            <img src={openIcon} alt={t("actions.openTab")} />
            {t("actions.openTab")}
          </ActionButton>
          <ActionButton onClick={handleShare}>
            <img src={shareIcon} alt={t("actions.share")} />
            {t("actions.share")}
          </ActionButton>
        </ButtonGroup>
      </LinkSection>
      <CodeSection>
        <Title>{t("settingTitle")}</Title>
      </CodeSection>
      <SettingsSection $isRTL={isRTL}>
        <CodeTitle>{t("settingsDescription")}</CodeTitle>
        <Settings $isRTL={isRTL}>
          <SettingGroup>
            <SettingLabel $isRTL={isRTL}>{t("setting.language")}</SettingLabel>
            <DropdownContainer>
              <StyledSelect
                $isRTL={isRTL}
                value={selectedLanguage.alpha2}
                onChange={(e) => {
                  const lang = availableLanguages.find(
                    (l) => l.alpha2 === e.target.value
                  );
                  if (lang) handleLanguageChange(lang);
                }}
              >
                {availableLanguages.map((lang) => (
                  <option key={lang.alpha2} value={lang.alpha2}>
                    {t(`${lang.English}`)}
                  </option>
                ))}
              </StyledSelect>
            </DropdownContainer>
          </SettingGroup>

          <SettingGroup>
            <SettingLabel $isRTL={isRTL}>{t("setting.age")}</SettingLabel>
            <DropdownContainer>
              <StyledSelect
                $isRTL={isRTL}
                value={selectedAge}
                onChange={(e) => setSelectedAge(e.target.value)}
              >
                {ageOptions.map((age) => (
                  <option key={age} value={age}>
                    {isRTL ? `${t("years")} ${age}` : `${age} ${t("years")}`}
                  </option>
                ))}
              </StyledSelect>
            </DropdownContainer>
          </SettingGroup>
          <SettingGroup>
            <SettingLabel $isRTL={isRTL}>{t("setting.answers")}</SettingLabel>
            <DropdownContainer>
              <StyledSelect
                $isRTL={isRTL}
                value={selectedAnswer}
                onChange={(e) => setSelectedAnswer(e.target.value)}
              >
                {availableAnswerLengths.map((answer) => (
                  <option key={answer} value={answer}>
                    {t(`answers.${answer?.toLowerCase()}`)}
                  </option>
                ))}
              </StyledSelect>
            </DropdownContainer>
          </SettingGroup>

          <SettingGroup $isRTL={isRTL}>
            <SettingLabel $isRTL={isRTL}>{t("setting.topics")}</SettingLabel>
            <SettingOptions $isRTL={isRTL}>
              {availableTopics.map((topic) => (
                <SettingOption
                  $isRTL={isRTL}
                  key={topic}
                  $isselected={selectedTopics.includes(topic)}
                  onClick={() => handleTopicToggle(topic)}
                  className="flex items-center justify-between"
                >
                  {t(`${topic}`)}
                  {selectedTopics.includes(topic) && (
                    <Check
                      size={18}
                      strokeWidth={3}
                      className="text-[#FF8A00] ml-2"
                    />
                  )}
                </SettingOption>
              ))}
            </SettingOptions>
          </SettingGroup>

          <SettingGroup>
            <SettingLabel $isRTL={isRTL} className="custom-topic">
              {t("setting.customTopic")}
            </SettingLabel>
            <CustomTopicInput
              type="text"
              value={customTopic}
              onChange={(e) => setCustomTopic(e.target.value)}
              disabled={true}
            />
            <Coming>{t("setting.comingSoon")}</Coming>
          </SettingGroup>

          <DialogButton
            onClick={handleUpdateSettings}
            disabled={isUpdating}
            style={{ alignSelf: "center", marginTop: "20px" }}
          >
            {isUpdating ? t("setting.updating") : t("setting.updateSettings")}
          </DialogButton>
        </Settings>
      </SettingsSection>
      <BirdImage src={birdIcon} alt="Bird" />
      <FoxImage src={catIcon} alt="Fox" />
      <SettingsDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onLogout={onLogout}
        onLanguageChange={handleLanguageChange}
        currentLanguage={selectedLanguage}
        availableLanguages={availableLanguages}
      />
      <ToastContainer />
    </SettingsContainer>
  );
};

export default SettingsPage;
