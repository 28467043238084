import styled from "styled-components";

interface RTLProps {
  $isRtl?: boolean;
}

export const WelcomeContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 4rem 2rem 2rem;
  background-color: #ffffff;
  color: #085cbe;
  font-weight: 700;
  overflow-x: hidden;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 5rem 1.5rem 2rem;
    justify-content: flex-start;
  }

  @media (max-width: 480px) {
    padding: 4.5rem 1rem 1.5rem;
  }
`;

export const TopRightContainer = styled.div<RTLProps>`
  position: fixed;
  top: 1rem;
  ${(props) => (props.$isRtl ? "right: 1rem;" : "right: 1rem;")}
  display: flex;
  align-items: center;
  z-index: 100;
  padding: 0.5rem;

  @media (max-width: 768px) {
    top: 0.75rem;
    ${(props) => (props.$isRtl ? "left: 0.75rem;" : "right: 0.75rem;")}
  }

  @media (max-width: 480px) {
    top: 0.5rem;
    ${(props) => (props.$isRtl ? "left: 0.5rem;" : "right: 0.5rem;")}
  }
`;

export const LangSwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export const Header = styled.header`
  margin-bottom: 2rem;
  width: 100%;
  padding: 0 1rem;

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
    padding: 0;
  }
`;

export const Title = styled.h1<RTLProps>`
  font-size: 48px;
  font-weight: bold;
  color: #085cbe;
  text-align: center;
  margin-bottom: 1rem;
  line-height: 1.2;
  direction: ${(props) => (props.$isRtl ? "rtl" : "ltr")};
  unicode-bidi: isolate;

  @media (max-width: 1024px) {
    font-size: 40px;
  }

  @media (max-width: 768px) {
    font-size: 36px;
  }

  @media (max-width: 480px) {
    font-size: 32px;
  }
`;

export const TitleSpan = styled.span<RTLProps>`
  display: inline-block;
  direction: ${(props) => (props.$isRtl ? "rtl" : "ltr")};
  unicode-bidi: isolate;
`;

export const Description = styled.p<RTLProps>`
  font-size: 24px;
  color: #085cbe;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto 1.5rem;
  line-height: 1.4;
  direction: ${(props) => (props.$isRtl ? "rtl" : "ltr")};
  unicode-bidi: isolate;

  @media (max-width: 1024px) {
    font-size: 20px;
    max-width: 800px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    max-width: 600px;
    margin-bottom: 1.25rem;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 1rem;
  }
`;

export const ContinueButton = styled.button`
  background-color: #085cbe;
  color: white;
  border: none;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 1rem;
  transition: all 0.3s ease;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 8px rgba(8, 92, 190, 0.3);

  &:hover {
    background-color: #0000dd;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
    margin-top: 0.5rem;
  }

  @media (max-width: 480px) {
    width: 44px;
    height: 44px;
  }
`;

export const BirdImage = styled.img`
  position: fixed;
  top: 15%;
  right: 0;
  width: 230px;
  height: auto;
  z-index: 0;
  pointer-events: none;
  transition: all 0.3s ease;

  @media (max-width: 1024px) {
    width: 180px;
    top: 12%;
  }

  @media (max-width: 768px) {
    width: 90px;
    top: 5%;
  }

  @media (max-width: 480px) {
    width: 60px;
    top: 8%;
  }
`;

export const FoxImage = styled.img<RTLProps>`
  position: fixed;
  bottom: 10%;
  ${(props) => (props.$isRtl ? "right: 0;" : "left: 0;")}
  width: 230px;
  height: auto;
  z-index: 0;
  pointer-events: none;
  transition: all 0.3s ease;

  @media (max-width: 1024px) {
    width: 180px;
    bottom: 8%;
  }

  @media (max-width: 768px) {
    width: 75px;
    bottom: 1%;
  }

  @media (max-width: 480px) {
    width: 55px;
    bottom: 3%;
  }
`;

export const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const DialogContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const DialogTitle = styled.h2`
  margin-bottom: 1rem;
  color: #334155;
`;

export const DialogText = styled.p`
  margin-bottom: 1.5rem;
  color: #64748b;
  line-height: 1.5;
`;

export const DialogButton = styled.button`
  background-color: #3b82f6;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: #2563eb;
  }
`;

export const InfoBox = styled.div`
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem 0;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  text-align: left;
`;
